<template>
	<div id="doctor">
		<div class="content" v-if="doctor">
			<dl class="breadcrumb">
				<dt>您當前位置:</dt>
				<dd><router-link to="/doctor/list">預約掛號</router-link>/</dd>
				<dd><router-link :to="'/doctor/'+doctor.globaluserid" v-text="doctor.username"></router-link>/</dd>
				<dd><span>{{breadcrumbText}}</span></dd>
			</dl>
			<div class="row">
				<div class="doctor-info">
					<div class="head">
						<span :class="{'icon-doctor': true, 'man': doctor.sex == 1}"></span>
						<div class="col">
							<h1 class="doctor-name" v-text="doctor.username"></h1>
							<div class="address-list">
								<router-link class="address" v-for="(address, i) in addressList" :key="i" :to="'/doctor/list?AreaLv1='+address.v1+'&AreaLv2='+address.v2" v-text="address.name"></router-link>
							</div>
						</div>
					</div>
					<div class="hint">所屬診所</div>
					<ul class="clinic-list">
						<li class="clinic"><router-link class="clinic-item" v-for="(clinic, i) in doctor.clinicList" :key="i" :to="'/hospital/detail/'+clinic.clinicid" v-text="'• '+clinic.clinicname"></router-link></li>
					</ul>
					<div class="hint s">畢業院校</div>
					<div class="value s" v-text="doctor.graduateschool"></div>
					<div class="hint y">畢業年份</div>
					<div class="value y" v-text="doctor.graduatedate"></div>
					<div class="hint i">個人簡介</div>
					<div class="value i" v-text="doctor.workexperience"></div>
				</div>
				<div class="col">
					<ul class="tabs">
						<li :class="{'tab': true, 'current': tabIndex == 1}"><router-link :to="'/doctor/'+doctor.globaluserid">供職醫院</router-link></li>
						<li :class="{'tab': true, 'current': tabIndex == 2}"><router-link :to="'/doctor/'+doctor.globaluserid + '/ask'">咨詢問答</router-link></li>
						<li :class="{'tab': true, 'current': tabIndex == 3}"><router-link :to="'/doctor/'+doctor.globaluserid + '/news'">健康資訊</router-link></li>
					</ul>
					<router-view class="router-view" :doctor="doctor"></router-view>
				</div>
			</div>
		</div>
		<div v-if="!doctor" style="position: relative; width: 100%; min-height: 550px; background-color: #fff; margin-top: 20px;">
			<loading v-if="loading" style="padding-top: 200px;"/>
		</div>
	</div>
</template>

<script>
	import Loading from '@/layouts/Loading.vue'
	import doctor_detail from '@/views/mixins/doctor_detail'
	export default {
		mixins: [doctor_detail],
		components: {
			Loading,
		},
		data(){
			return {
				addressList: [],
				tabIndex: 1,
				breadcrumbText: '醫師簡介',
				doctor: null,
				loading: true,
			}
		},
		mounted: function(){
			var doctorId = this.$route.params.doctorId
			if(parseInt(doctorId) == doctorId) {
				var path = this.$route.path
				this.breadcrumbText = '醫師簡介'
				this.tabIndex = 1
				if(/^\/doctor\/\d{1,}\/ask/.test(path)) {
					this.tabIndex = 2
					this.breadcrumbText = '咨詢問答'
				} else if(/^\/doctor\/\d{1,}\/news/.test(path)) {
					this.tabIndex = 3
					this.breadcrumbText = '健康資訊'
				}
				this.onRefresh()
			} else {
				this.$router.push('/doctor/list')
			}
		},
		methods: {
			onRefresh: function() {
				this.loading = true
				var doctor = JSON.parse(sessionStorage.getItem('detail'))
				this.loadClinicList({
					globaluserid: this.$route.params.doctorId
				}, (_data, _error)=>{
					this.loading = false
					if(_data) {
						var addressList = []
						_data.forEach((d)=>{
							if(!addressList.find(v=>v.v2==d.arealevel2id)) {
								addressList.push({
									v1: d.arealevel1id,
									v2: d.arealevel2id,
									name: d.arealevel2name,
								})
							}
						})
						this.addressList = addressList
						this.doctor = {
							...doctor,
							clinicList: _data
						}
					} else {
						if(_error) {
							this._toast.warning(this, _error)
						}
					}
				})
			}
		},
		watch: {
			$route(to,from){
				var path = to.path
				this.breadcrumbText = '醫師簡介'
				this.tabIndex = 1
				if(/^\/doctor\/\d{1,}\/ask$/.test(path)) {
					this.tabIndex = 2
					this.breadcrumbText = '咨詢問答'
				} else if(/^\/doctor\/\d{1,}\/news$/.test(path)) {
					this.tabIndex = 3
					this.breadcrumbText = '健康資訊'
				}
				this.onRefresh()
			}
		}
	};
</script>

<style lang="scss" scoped>
   #doctor {
	   width: 100%;
   }

   #doctor .breadcrumb {
	   display: flex;
	   flex-direction: row;
	   width: 1000px;
	   height: 42px;
	   box-sizing: border-box;
	   padding: 0px 16px;
	   background-color: #fff;
	   align-items: center;
	   margin: 20px auto;
	   font-size: 16px;
	   line-height: 15px;
	   font-weight: 400;
	   color: #969696;
	   user-select: none;
   }

	#doctor .breadcrumb dt {
		margin-right: 8px;
	}

	#doctor .breadcrumb dd {
	   color: #646464;
	   line-height: 17px;
	}

   #doctor .breadcrumb a {
	   margin: 0px 14px;
	   text-decoration: underline;
   }

   #doctor .breadcrumb span {
	   margin-left: 14px;
   }

   #doctor .row {
	   display: flex;
	   flex-direction: row;
	   width: 1000px;
	   margin: 0px auto;
   }

   #doctor .row > .doctor-info {
	   display: flex;
	   flex-direction: column;
	   width: 258px;
	   background-color: #fff;
	   margin-right: 10px;
	   padding-bottom: 40px;
   }

	#doctor .row > .doctor-info > .head {
		display: flex;
		flex-direction: row;
		height: 82px;
		width: 100%;
		box-sizing: border-box;
		padding: 18px 8px 8px 8px;
	}

	#doctor .row > .doctor-info > .head > .icon-doctor {
		width: 56px;
		height: 56px;
		margin-right: 8px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
		background-image: url(../../../assets/imgs/pc/img_nvys@2x.png);
	}

	#doctor .row > .doctor-info > .head > .icon-doctor.man {
		background-image: url(../../../assets/imgs/pc/img_nanys@2x.png);
	}

	#doctor .row > .doctor-info > .head > .col {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	
	.doctor-name {
		color: #242020;
		font-size: 18px;
		font-weight: bold;
		margin-top: 6px;
		margin-bottom: 12px;
		line-height: 18px;
	}

	.address-list {
		position: relative;
		box-sizing: border-box;
		padding-left: 16px;
	}

	.address-list::after {
		content: '';
		position: absolute;
		left: 0;
		top: 4px;
        width: 9px;
        height: 11px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_cdibiao@2x.png);
	}

	.address-list > .address {
		color: #646464;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-right: 8px;
	}

	.address-list > .address:last-child {
		margin-right: 0px;
	}

	#doctor .row > .doctor-info > .hint {
		font-size: 14px;
		color: #646464;
		font-weight: 400;
		line-height: 16px;
		height: 16px;
		padding-left: 25px;
		position: relative;
		box-sizing: border-box;
		margin: 24px 8px 0px 8px;
	}

	#doctor .row > .doctor-info > .hint::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 16px;
		height: 15px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_yiyuan@2x.png);
	}

	#doctor .row > .doctor-info > .hint.s::after {
		width: 17px;
		height: 16px;
        background-image: url(../../../assets/imgs/pc/img_biye@2x.png);
	}

	#doctor .row > .doctor-info > .hint.y::after {
		width: 16px;
		height: 15px;
        background-image: url(../../../assets/imgs/pc/img_nianfen@2x.png);
	}

	#doctor .row > .doctor-info > .hint.i::after {
		width: 15px;
		height: 16px;
        background-image: url(../../../assets/imgs/pc/img_jiehsao@2x.png);
	}

	#doctor .row > .doctor-info > .clinic-list {
		display: flex;
		flex-direction: column;
		padding-left: 33px;
		box-sizing: border-box;
		width: 100%;
	}

	#doctor .row > .doctor-info > .clinic-list > .clinic {
		display: flex;
		flex-direction: column;
	}

	#doctor .row > .doctor-info > .clinic-list > .clinic > .clinic-item {
		color: #231F20;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400;
		margin-top: 13px;
	}

	#doctor .row > .doctor-info > .value {
		color: #242020;
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		margin-left: 33px;
		margin-top: 13px;
		margin-right: 8px;
	}

   #doctor .row > .col {
	   display: flex;
	   flex-direction: column;
	   flex: 1;
   }

   #doctor .row > .col > .tabs {
	   display: flex;
	   flex-direction: row;
	   height: 60px;
	   width: 100%;
	   box-sizing: border-box;
	   background-color: #fff;
	   justify-content: flex-start;
	   align-items: center;
	   margin-bottom: 1px;
	   padding: 0px 16px;
   }

   #doctor .row > .col > .tabs > .tab {
	   margin-right: 60px;
	   color: #969696;
	   font-size: 16px;
	   font-weight: 400;
	   line-height: 16px;
	   position: relative;
   }

   #doctor .row > .col > .tabs > .tab.current {
	   color: #242020;
	   font-weight: bold;
	   position: relative;
   }

	#doctor .row > .col > .tabs > .tab.current::after {
		content: '';
		position: absolute;
		top: 26px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #242020;
		height: 4px;
		width: 20px;
	}

	#doctor .row > .col > .router-view {
	   background-color: #fff;
	   display: flex;
	   flex-direction: column;
   }
</style>
